<form [formGroup]="formDocuments" (submit)="onSubmitDocuments()" class="col-sm-12">
  <h4 class="text-center mb-4">3. VOS DOCUMENTS</h4>

  <h5 style="color: #1b6381" class="ml-n3 mb-4">Vous pourrez compléter cette étape plus tard, une fois vos identifiants reçus.</h5>

  <div *ngIf="f.cv.errors" class="ml-n3">
    <label class="text-danger" *ngIf="f.cv.errors.required && f.cv.touched">Vous devez fournir votre CV</label>
    <label class="text-danger" *ngIf="f.cv.errors.maxSize && f.cv.touched">La taille du document spécifié est supérieur à 10Mo, sélectionnés un document plus petit</label>
  </div>
  <div class="form-group">
    <div class="row">
      <div class="col-md-12 px-0" *ngIf="!f.cv.value.isUploading && !f.cv.value.isFinished">
        <span class="mb-1">Joindre votre CV <i class="fas fa-paperclip"></i></span>
        <div class="custom-file">
          <input type="file" class="custom-file-input" id="inputcv" (change)="onFileChange($event, f.cv, 'CV', 'CV candidat')"
                 aria-describedby="inputcv">
          <label class="custom-file-label" for="inputcv" *ngIf="!f.cv.value.file">Choisir un fichier ...</label>
          <label class="d-block d-sm-none custom-file-label" for="inputcv" *ngIf="!f.cv.value.file">Fichier ...</label>
          <label class="custom-file-label file-name-overflow" for="inputcv" *ngIf="f.cv.value.file">{{ f.cv.value.file.name
            }}</label>
        </div>
      </div>
      <div *ngIf="f.cv.value.isUploading && !f.cv.value.isFinished"
           class="col-sm-12 custom-file form-check-inline ml-auto pr-0 mr-0">
        <label class="ml-sm-1">Envoi en cours... {{ f.cv.value.progress }}%</label>
        <progress max="100" value="{{ f.cv.value.progress }}" class="upload-bar"></progress>
      </div>
      <div *ngIf="!f.cv.value.isUploading && f.cv.value.isFinished"
           class="col-sm-12 custom-file form-check-inline ml-auto pr-0 mr-0 mr-sm-3">
        <label class="ml-sm-1">Votre CV a bien été envoyé</label>
      </div>
    </div>
  </div>

  <div *ngIf="f.lettre_motivation.errors" class="ml-n3">
    <label class="text-danger" *ngIf="f.lettre_motivation.errors.required && f.lettre_motivation.touched">Vous devez fournir une lettre de motivation</label>
    <label class="text-danger" *ngIf="f.lettre_motivation.errors.maxSize && f.lettre_motivation.touched">La taille du document spécifié est supérieur à 10Mo, sélectionnés un document plus petit</label>
  </div>
  <div class="form-group">
    <div class="row">
      <div class="col-md-12 px-0" *ngIf="!f.cv.value.isUploading && !f.cv.value.isFinished">
        <span class="mb-1">Joindre votre Lettre de Motivation <i class="fas fa-paperclip"></i></span>
        <div class="custom-file">
          <input type="file" class="custom-file-input" id="lettre_motivation"
                 aria-describedby="lettre_motivation" (change)="onFileChange($event, f.lettre_motivation, 'LETTRE_MOTIVATION', 'lettre_de_motivation_candidat')">
          <label class="custom-file-label" for="inputcv" *ngIf="!f.lettre_motivation.value.file">Choisir un fichier ...</label>
          <label class="d-block d-sm-none custom-file-label" for="lettre_motivation" *ngIf="!f.lettre_motivation.value.file">Fichier ...</label>
          <label class="custom-file-label file-name-overflow" for="lettre_motivation" *ngIf="f.lettre_motivation.value.file">{{ f.lettre_motivation.value.file.name }}</label>
        </div>
      </div>
      <div *ngIf="f.lettre_motivation.value.isUploading && !f.lettre_motivation.value.isFinished"
           class="col-sm-12 custom-file form-check-inline ml-auto pr-0 mr-0">
        <label class="ml-sm-1">Envoi en cours... {{ f.lettre_motivation.value.progress }}%</label>
        <progress max="100" value="{{ f.lettre_motivation.value.progress }}" class="upload-bar"></progress>
      </div>
      <div *ngIf="!f.lettre_motivation.value.isUploading && f.lettre_motivation.value.isFinished"
           class="col-sm-12 custom-file form-check-inline ml-auto pr-0 mr-0 mr-sm-3">
        <label class="ml-sm-1">Votre lettre de motivation a bien été envoyé</label>
      </div>
    </div>
  </div>

  <div *ngIf="!isLoading" class="form-row ">
    <div class="form-group col-md-12 d-flex justify-content-center">
      <input type="submit" name="next" class="next action-button" value="VALIDER" style="background: #27AE60" />
    </div>
  </div>
</form>
