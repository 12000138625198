import { Injectable } from '@angular/core';
import { HttpClient, HttpEventType, HttpHeaders, HttpRequest } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { environment } from '../environments/environment';
import {ApiSuccessResponse, Diplome, Formation, Profil360} from './app.models';

@Injectable()
export class AppService {

  private token_candidat: string = null;
  private id_profil360: number = null;
  public profil360: Profil360 = null;
  constructor(private httpClient: HttpClient) { }

  static handleUploadProgress(event) {
    switch (event.type) {
      case HttpEventType.UploadProgress:
        return Math.round(100 * event.loaded / event.total);

      case HttpEventType.Response:
        return 100;

      default:
        return 0;
    }
  }

  fetchListeFormations() {
    return this.httpClient.get<ApiSuccessResponse<Formation[]>>(
      `${environment.api_base_url}/ecoles/${environment.id_ecole}/formations`,
      {
        observe: 'body',
        responseType: 'json'
      })
      .pipe(
        map((data: ApiSuccessResponse<Formation[]>) => {
          return data.results;
        })
      );
  }

  createProfilCandidat(profil360: Profil360) {
    return this.httpClient.post<ApiSuccessResponse<{token: string, id_profil360: number}>>(
      `${environment.api_base_url}/ecoles/${environment.id_ecole}/candidats?token=1`,
      profil360,
      {
        observe: 'body',
        responseType: 'json'
      })
      .pipe(
        map((data: ApiSuccessResponse<{token: string, id_profil360: number}>) => {
          this.token_candidat = data.results.token;
          this.id_profil360 = data.results.id_profil360;
          return data.results;
        })
      );
  }

  getProfil360() {
    return this.httpClient.get<ApiSuccessResponse<Profil360>>(
      `${environment.api_base_url}/profil360/${this.id_profil360}`,
      {
        observe: 'body',
        responseType: 'json',
        headers: new HttpHeaders({
          Authorization: `Bearer ${this.token_candidat}`
        })
      })
      .pipe(
        map((data: ApiSuccessResponse<Profil360>) => {
          return data.results;
        })
      );
  }

  updateProfil360(profil360) {
    return this.httpClient.put<ApiSuccessResponse<any>>(
      `${environment.api_base_url}/profil360/${this.id_profil360}`,
      profil360,
      {
        observe: 'body',
        responseType: 'json',
        headers: new HttpHeaders({
          Authorization: `Bearer ${this.token_candidat}`
        })
      })
      .pipe(
        map((data: ApiSuccessResponse<any>) => {
          return data.results;
        })
      );
  }

  setFormationsCandidat(id_formation: number) {
    return this.httpClient.post<ApiSuccessResponse<any>>(
      `${environment.api_base_url}/profils360/${this.id_profil360}/candidatures`, {
        id_formation
      },
      {
        observe: 'body',
        responseType: 'json',
        headers: new HttpHeaders({
          Authorization: `Bearer ${this.token_candidat}`
        })
      })
      .pipe(
        map((data: ApiSuccessResponse<any>) => {
          return data.results;
        })
      );
  }

  addResponsableLegal(responsableLegal) {
    return this.httpClient.post<ApiSuccessResponse<any>>(
      `${environment.api_base_url}/profil360/${this.id_profil360}/responsables_legaux`,
      responsableLegal,
      {
        observe: 'body',
        responseType: 'json',
        headers: new HttpHeaders({
          Authorization: `Bearer ${this.token_candidat}`
        })
      })
      .pipe(
        map((data: ApiSuccessResponse<any>) => {
          return data.results;
        })
      );
  }

  addDiplomeCandidat(diplome: Diplome) {
    return this.httpClient.post<ApiSuccessResponse<any>>(
      `${environment.api_base_url}/profil360/${this.id_profil360}/diplomes`,
      diplome,
      {
        observe: 'body',
        responseType: 'json',
        headers: new HttpHeaders({
          Authorization: `Bearer ${this.token_candidat}`
        })
      })
      .pipe(
        map((data: ApiSuccessResponse<any>) => {
          return data.results;
        })
      );
  }

  updateValeurChampsPerso(id_def_champ_perso: number, valeur: string) {
    return this.httpClient.post<ApiSuccessResponse<any>>(
      `${environment.api_base_url}/profil360/${this.id_profil360}/champs_perso_profil360/${id_def_champ_perso}`, {
        valeur
      },
      {
        observe: 'body',
        responseType: 'json',
        headers: new HttpHeaders({
          Authorization: `Bearer ${this.token_candidat}`
        })
      })
      .pipe(
        map((data: ApiSuccessResponse<any>) => {
          return data.results;
        })
      );
  }

  sendDocumentCandidat(intitule: string, type_document: string, document: File) {
    const headers = new HttpHeaders();
    const formData = new FormData();
    headers.append('Content-Type', 'multipart/form-data');
    formData.append('id_profil360', String(this.id_profil360));
    formData.append('intitule', intitule);
    formData.append('type', type_document);
    formData.append('document', document);
    const uploadReq = new HttpRequest('POST', `${environment.api_base_url}/profil360/${this.id_profil360}/documents`, formData, {
      reportProgress: true,
      headers: new HttpHeaders({
        Authorization: `Bearer ${this.token_candidat}`
      })
    });
    return this.httpClient.request(uploadReq);
  }
}



