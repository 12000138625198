import { Component, Output, EventEmitter } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup } from '@angular/forms';
import { ValidateDocument } from '../../validators/document.validator';
import { AppService } from '../../app.service';
import { last, map, tap } from 'rxjs/operators';
import { forkJoin, of } from 'rxjs';
import { HttpEventType } from '@angular/common/http';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-form-documents-candidat',
  templateUrl: './form-documents-candidat.component.html',
  styleUrls: ['./form-documents-candidat.component.css']
})
export class FormDocumentsCandidatComponent {

  public formDocuments: FormGroup = null;
  public isLoading = false;

  @Output() nextStep: EventEmitter<string> = new EventEmitter();

  constructor(
      private fb: FormBuilder,
      private toastr: ToastrService,
      private appService: AppService,
  ) {
    this.formDocuments = this.fb.group({
      cv: [
        {file: null, type: null, nom: null, isUploading: false, isFinished: false, progress: 0}, ValidateDocument(false, 10 * 1024 * 1024)
      ],
      lettre_motivation: [
        {file: null, type: null, nom: null, isUploading: false, isFinished: false, progress: 0}, ValidateDocument(false, 10 * 1024 * 1024)
      ]
    });
  }

  static handleUploadProgress(event) {
    switch (event.type) {
      case HttpEventType.UploadProgress:
        return Math.round(100 * event.loaded / event.total);

      case HttpEventType.Response:
        return 100;

      default:
        return 0;
    }
  }

  /* Permet un accès facile aux champs du formulaire ren retournant la liste des AbstractControl */
  get f() {
    return this.formDocuments.controls;
  }

  onFileChange(event, control: AbstractControl, type = null, nom = null) {
    control.patchValue({
      ...control.value,
      type: type,
      nom: nom,
      file: event.target.files[0]
    });
  }

  uploadDocument(control: AbstractControl) {
    if (control.value.file == null) {
      control.patchValue({
        ...control.value,
        isUploading: false,
        isFinished: true,
      });

      return of(null);
    }

    control.patchValue({
      ...control.value,
      isUploading: true,
    });

    return this.appService.sendDocumentCandidat(control.value.nom, control.value.type, control.value.file)
        .pipe(
            map(event => FormDocumentsCandidatComponent.handleUploadProgress(event)),
            tap((progress) => {
              control.patchValue({
                ...control.value,
                progress: progress,
              });
            }),
            last()
        ).pipe(
            map((data: any) => {
              control.patchValue({
                ...control.value,
                isUploading: false,
                isFinished: true,
              });
              return data;
            })
        );
  }

  onSubmitDocuments() {
    const observables = [];

    if (!this.formDocuments.valid) {
      this.formDocuments.markAllAsTouched();
      this.toastr.error(`Merci de vérifier les données saisies dans le formulaire`, `Saisie invalide`);
    } else {
      this.isLoading = true;

      observables.push(this.uploadDocument(this.formDocuments.controls.cv));
      observables.push(this.uploadDocument(this.formDocuments.controls.lettre_motivation));

      forkJoin(observables).subscribe(
          () => {
            this.nextStep.emit('STEP_FINAL');
          },
          () => {
            this.nextStep.emit('STEP_FINAL');
          },
          () => {
            this.nextStep.emit('STEP_FINAL');
          }
      );
    }
  }
}
