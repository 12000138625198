import * as moment from 'moment-timezone';
import localeFr from '@angular/common/locales/fr';
import localeFrExtra from '@angular/common/locales/extra/fr';
import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { NgbDateAdapter, NgbDateParserFormatter, NgbDatepickerI18n, NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AppService } from './app.service';
import { HttpClientModule } from '@angular/common/http';
import { FormInfosCandidatComponent } from './forms/form-infos-candidat/form-infos-candidat.component';
import { FormFormationsCandidatComponent } from './forms/form-formations-candidat/form-formations-candidat.component';
import { registerLocaleData } from '@angular/common';
import { FormDocumentsCandidatComponent } from './forms/form-documents-candidat/form-documents-candidat.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ToastrModule } from 'ngx-toastr';
import { CustomDatepickerI18n, I18n } from './config/ng-bootstrap-datepicker-i18n';
import { NgbDateFRParserFormatter } from './config/ngb-date-fr-parser-formatter';
import { NgbIsoDateAdapter } from './config/ngb-iso-date-adapter';

@NgModule({
  declarations: [
    AppComponent,
    FormInfosCandidatComponent,
    FormFormationsCandidatComponent,
    FormDocumentsCandidatComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    ToastrModule.forRoot(),
    AppRoutingModule,
    NgbModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
  ],
  providers: [
    AppService,
    I18n,
    {provide: NgbDatepickerI18n, useClass: CustomDatepickerI18n},
    {provide: NgbDateParserFormatter, useClass: NgbDateFRParserFormatter},
    {provide: NgbDateAdapter, useClass: NgbIsoDateAdapter}
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
  constructor() {
    // Paramétrer la locale d'angular en français + forcer le timezone de moment.js à celui de Paris
    moment.tz.setDefault('Europe/Paris');
    registerLocaleData(localeFr, 'fr-FR', localeFrExtra);
  }
}
