<form [formGroup]="formFormations" (submit)="onSubmitFormations()">
  <h4 class="text-center mb-4">2. VOTRE / VOS SOUHAIT(S) DE FORMATION(S)</h4>

  <div class="mb-4">
    <label>Formation(s) souhaitée(s) <span class="text-danger">*</span> :</label>
  </div>

  <div *ngIf="f.formations.errors" class="mb-4">
    <label class="font-weight-bold text-danger" *ngIf="f.formations.errors.minFormations && f.formations.touched">Vous devez indiquer au moins une formation</label>
  </div>

  <div class="mb-4" *ngFor="let categorie of liste_categories_formations" formArrayName="formations">
    <h5 class="fs-title-small-alt"><strong>{{ categorie.nom }}</strong></h5>
    <div *ngFor="let formation of categorie.formations">
      <div class="custom-control custom-checkbox" *ngIf="formation.groupFormations == null">
        <input type="checkbox" class="custom-control-input" [value]="formation.id" [formControlName]="formation.form_idx" id="formation-{{ formation.id }}">
        <label class="custom-control-label" for="formation-{{ formation.id }}">{{ formation.nom }}</label>
      </div>
      <div *ngIf="formation.groupFormations != null">
        <div class="custom-control custom-checkbox mb-2" *ngIf="formation.nomGroup">
          <label>
            <span class="ml-3">{{ formation.nomGroup }}</span>
          </label>
        </div>
        <div class="ml-3 mb-4">
          <div class="custom-control custom-checkbox ml-4" *ngFor="let formationOfGroup of formation.groupFormations">
            <input type="checkbox" class="custom-control-input" [value]="formationOfGroup.id" [formControlName]="formationOfGroup.form_idx" id="formation-{{ formationOfGroup.id }}">
            <label class="custom-control-label" for="formation-{{ formationOfGroup.id }}">{{ formationOfGroup.nom }}</label>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="form-row ">
    <div class="form-group col-md-12 d-flex justify-content-center">
      <button *ngIf="!isLoading" type="submit" class="next action-button">SUIVANT</button>
      <button *ngIf="isLoading" type="submit" disabled class="next action-button">
        <img src="../../../assets/loading.gif" alt="Chargement en cours..." height="44">
      </button>
    </div>
  </div>
</form>
