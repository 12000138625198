import { Component, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';
import { ValidateFormations } from '../../validators/formations.validator';
import { FormBuilder, FormGroup } from '@angular/forms';
import { AppService } from '../../app.service';
import { forkJoin } from 'rxjs';
import { ToastrService } from 'ngx-toastr';
import { environment } from '../../../environments/environment';
import {Profil360} from '../../app.models';

@Component({
    selector: 'app-form-formations-candidat',
    templateUrl: './form-formations-candidat.component.html',
    styleUrls: ['./form-formations-candidat.component.css']
})
export class FormFormationsCandidatComponent implements OnInit, OnChanges {

    @Input() liste_formations: any[];
    @Input() formInfoCandidat: Profil360 = null;

    public formFormations: FormGroup = null;
    public isLoading = false;
    public liste_categories_formations: any = environment.configuration_formations;

    @Output() nextStep: EventEmitter<string> = new EventEmitter();

    constructor(private fb: FormBuilder,
                private toastr: ToastrService,
                private appService: AppService) {
        this.formFormations = this.createFormFormations();

        this.liste_categories_formations.forEach((category, cat_idx) => {
            category.formations.forEach((formation, formation_idx) => {

                if (formation.groupFormations != null) {
                    formation.groupFormations.forEach((formationOfGroup, formation_of_group_idx) => {
                        this.liste_categories_formations[cat_idx]
                            .formations[formation_idx].groupFormations[formation_of_group_idx].form_idx =
                            this.findFormationIndexById(formationOfGroup.id);
                    });
                } else {
                    this.liste_categories_formations[cat_idx].formations[formation_idx].form_idx =
                        this.findFormationIndexById(formation.id);
                }

            });
        });
    }

    get f() {
        return this.formFormations.controls;
    }

    getAllReducedFormation() {
        return this.liste_categories_formations
            .reduce((reduced_formations, category) => {

                category.formations.forEach((formation) => {
                    if (formation.groupFormations != null) {
                        reduced_formations = reduced_formations.concat(formation.groupFormations);
                    } else {
                        reduced_formations.push(formation);
                    }
                });

                return reduced_formations;
            }, []);
    }

    findFormationIndexById(id_formation_base64: string) {
        return this.getAllReducedFormation().findIndex((formation) => {
            return formation.id === id_formation_base64;
        });
    }

    ngOnInit() {
        this.formFormations = this.createFormFormations();
        console.log(this.formInfoCandidat);
    }

    ngOnChanges() {
        this.formFormations = this.createFormFormations();
    }

    createProfil360() {
        this.appService.createProfilCandidat(this.formInfoCandidat).subscribe(
            () => {
                this.sendFormFormations();
            },
            () => {
                this.toastr.error(`Echec de la création de votre candidature, merci de réessayer ultérieurement`);
                this.isLoading = false;
            }
        );
    }

    sendFormFormations() {
      const selected_formations = this.getSelectedFormations();
      const observable = [];

      selected_formations.forEach((formation) => {
        observable.push(this.appService.setFormationsCandidat(formation.id));
      });

      forkJoin(observable).subscribe(
        () => {
          this.nextStep.emit('STEP_3');
          this.isLoading = false;
        },
        () => {
          this.nextStep.emit('STEP_3');
          this.isLoading = false;
        }
      );
    }

    createFormFormations() {
        const formations = this.getAllReducedFormation();
        return this.formFormations = this.fb.group({
            formations: this.fb.array(
                formations.map(formation => false)
                , ValidateFormations),
        });
    }

    getSelectedFormations(): any[] {
        const all_flatten_formations = this.getAllReducedFormation();
        return this.formFormations.value.formations
            .map((is_checked, idx) => is_checked ? all_flatten_formations[idx] : null)
            .filter(formation => formation != null);
    }

    onSubmitFormations() {
        if (!this.formFormations.valid) {
            this.formFormations.get('formations').markAsTouched();
            this.toastr.error(`Vous devez cocher au moins une formation`, `Aucune formation choisie`);
        } else {
            this.isLoading = true;
            this.createProfil360();
        }
    }
}
