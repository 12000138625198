import { Component, EventEmitter, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AppService } from '../../app.service';
import { NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { SymfonyEmailValidator } from '../../validators/symfony-email.validator';
import {Profil360} from '../../app.models';

@Component({
  selector: 'app-form-infos-candidat',
  templateUrl: './form-infos-candidat.component.html',
  styleUrls: ['./form-infos-candidat.component.css']
})
export class FormInfosCandidatComponent {

  public formInfoCandidat: FormGroup = null;
  public isLoading = false;
  public maxDateNaissance: NgbDateStruct = null;
  public minDateNaissance: NgbDateStruct = null;

  @Output() nextStep: EventEmitter<string> = new EventEmitter();
  @Output() formCandidat: EventEmitter<Profil360> = new EventEmitter();

  constructor(
      private fb: FormBuilder,
      private appService: AppService,
      private toastr: ToastrService
  ) {
    this.formInfoCandidat = this.fb.group({
      civilite: ['H', Validators.required],
      nom: ['', Validators.required],
      prenom: ['', Validators.required],
      date_naissance: ['', Validators.required],
      portable: ['', Validators.required],
      email: ['', [Validators.required, SymfonyEmailValidator]],
      code_postal: ['', Validators.required],
      ville: ['', Validators.required],
      provenance: ['candidature-cfai-77']
    });

    const now = new Date();

    this.maxDateNaissance = {
      year: now.getFullYear(),
      month: (now.getMonth() + 1),
      day: now.getDate()
    };

    this.minDateNaissance = {
      year: 1930,
      month: 1,
      day: 1
    };
  }

  get f() {
    return this.formInfoCandidat.controls;
  }

  onSubmitInfoCandidat() {
    if (!this.formInfoCandidat.valid) {
      this.toastr.error(`Merci de vérifier les données saisies dans le formulaire`, `Saisie invalide`);
      this.formInfoCandidat.markAllAsTouched();
    } else {
      this.nextStep.emit('STEP_2');
      this.formCandidat.emit(this.formInfoCandidat.value);
    }
  }
}
