import { Component, OnInit } from '@angular/core';
import { AppService } from './app.service';
import {Formation, Profil360} from './app.models';
import {FormGroup} from '@angular/forms';


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit {

  public step = 'STEP_1';
  public listeFormations: Formation[] = [];
  public formCandidat: Profil360 = null;

  constructor(private appService: AppService) { }

  ngOnInit() {
    this.appService.fetchListeFormations().subscribe(
        (listeFormations: Formation[]) => {
          this.listeFormations = listeFormations;
        },
        (bad) => {
          console.error(bad);
        }
    );
  }

  goToNextStep(step) {
    this.step = step;
  }

  sendFormCandidat(form_profil) {
    this.formCandidat = form_profil;
  }
}
