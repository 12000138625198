<div id="container-banniere" class="text-center">
  <div id="spacer-banniere">
    <img src="../assets/banniere-cfai-77.png" id="banniere" alt="Banierre du CFAI 77 format PC"/>
  </div>
</div>

<div id="steps-block">
  <!-- progressbar -->
  <ul id="progressbar">
    <li class="active">Vos coordonnées<br>personnelles</li>
    <li [ngClass]="{'active': step == 'STEP_2' || step == 'STEP_3' || step == 'STEP_FINAL'}">Votre / Vos souhait(s) de formation(s)</li>
    <li [ngClass]="{'active': step == 'STEP_3' || step == 'STEP_FINAL'}">Vos documents</li>
  </ul>
</div>
<!-- multistep form -->
<div id="msform">
  <!-- fieldsets -->
  <fieldset *ngIf="step == 'STEP_1'" class="form-step etape-1">
    <app-form-infos-candidat (nextStep)="goToNextStep($event)" (formCandidat)="sendFormCandidat($event)"></app-form-infos-candidat>
  </fieldset>
  <fieldset *ngIf="step == 'STEP_2'" class="form-step etape-2">
    <app-form-formations-candidat [liste_formations]="listeFormations" [formInfoCandidat]="formCandidat" (nextStep)="goToNextStep($event)"></app-form-formations-candidat>
  </fieldset>
  <fieldset *ngIf="step == 'STEP_3'" class="form-step etape-2">
  <app-form-documents-candidat (nextStep)="goToNextStep($event)"></app-form-documents-candidat>
  </fieldset>
  <fieldset *ngIf="step == 'STEP_FINAL'" class="form-step etape-3">
    <h2 class="fs-title text-center">Candidature envoyée</h2>
    <h3 class="fs-subtitle text-center">Votre candidature a bien été envoyée</h3>
    <div class="checkmark">
      <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg"
           xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
           viewBox="0 0 161.2 161.2" enable-background="new 0 0 161.2 161.2" xml:space="preserve">
                  <circle class="circle" fill="none" stroke="#33b057" stroke-width="7" stroke-miterlimit="10" cx="80.6" cy="80.6"
                          r="62.1"></circle>
        <polyline class="icon" fill="none" stroke="#33b057" stroke-width="7" stroke-linecap="round"
                  stroke-miterlimit="10" points="113,52.8 74.1,108.4 48.2,86.4"></polyline>
                </svg>
    </div>

  </fieldset>
</div>
