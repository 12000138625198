<form [formGroup]="formInfoCandidat" (submit)="onSubmitInfoCandidat()" method="POST">
  <h4 class="text-center mb-4">1. VOS COORDONNÉES PERSONNELLES</h4>
  <h3 class="fs-subtitle"><span class="text-danger">*</span> champ obligatoire</h3>
  <div class="form-row">
    <div class="form-group col-md-12">
      <label for="civilite-select">Civilité <span class="text-danger">*</span></label>
      <select class="form-control" formControlName="civilite" id="civilite-select">
        <option value="H">Monsieur</option>
        <option value="F">Madame</option>
      </select>
    </div>
  </div>
  <div class="form-row">
    <div class="form-group col-md-6">
      <label for="nom">Nom <span class="text-danger">*</span></label>
      <input type="text" id="nom" formControlName="nom" class="form-control">
    </div>
    <div class="form-group col-md-6">
      <label for="prenom">Prénom <span class="text-danger">*</span></label>
      <input type="text" id="prenom" formControlName="prenom" class="form-control">
    </div>
  </div>
  <div class="form-row">
    <div class="form-group col-md-6">
      <label for="date-naissance">Date de naissance <span class="text-danger">*</span></label>
      <div class="input-group">
        <input class="form-control" placeholder="JJ/MM/AAAA"
               formControlName="date_naissance"
               [maxDate]="maxDateNaissance"
               [minDate]="minDateNaissance"
               ngbDatepicker
               #dateNaissance="ngbDatepicker"
               id="date-naissance">
        <div class="input-group-append">
          <button class="btn btn-outline-secondary calendar" (click)="dateNaissance.toggle()"
                  type="button"></button>
        </div>
      </div>
    </div>
  </div>
  <div class="form-row">
    <div class="form-group col-md-6">
      <label for="telportable">Téléphone Portable <span class="text-danger">*</span></label>
      <input type="text" id="telportable" formControlName="portable" class="form-control">
    </div>
    <div class="form-group col-md-6">
      <label for="email">Email <span class="text-danger">*</span></label>
      <input type="email" id="email" formControlName="email" class="form-control">
    </div>
  </div>
  <div class="form-row">
    <div class="form-group col-md-6">
      <label for="code_postal">Code postal <span class="text-danger">*</span></label>
      <input type="text" id="code_postal" formControlName="code_postal" class="form-control">
    </div>
    <div class="form-group col-md-6">
      <label for="ville">Ville<span class="text-danger">*</span></label>
      <input type="text" id="ville" formControlName="ville" class="form-control">
    </div>
  </div>
  <div class="form-row ">
    <div class="form-group col-md-12 d-flex justify-content-center">
      <button *ngIf="!isLoading" type="submit" class="next action-button">SUIVANT</button>
      <button *ngIf="isLoading" type="submit" disabled class="next action-button">
        <img src="../../../assets/loading.gif" alt="Chargement en cours..." height="44">
      </button>
    </div>
  </div>
</form>
