import { AbstractControl } from '@angular/forms';

export function ValidateFormations(control: AbstractControl) {
  // Vérifi si il y a au moins une entrée du tableau de formations selectionnnées qui vaux true
  if (control.value instanceof Array && control.value.filter(value => value === true).length === 0) {
    // Retourne l'erreur qui est survenue lors de la validation
    return { minFormations: true };
  }
  // Return null si aucune erreur
  return null;
}
