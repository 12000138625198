export const environment = {
  production: true,
  api_base_url: 'https://api-v2.hub3e.com/v2',
  id_ecole: 3,
  configuration_formations: [
    {
      nom: `- SITE D'EMERAINVILLE -`,
      formations: [
        {
          id: 'GROUP-1',
          nomGroup: '',
          groupFormations: [
            {id: 986, nom: 'BAC PRO MELEC - Métiers de l’Electricité et de ses Environnements Connectés'},
            {id: 985, nom: 'BAC PRO MEI - Maintenance des Equipements Industriels'},
            {id: 981, nom: 'BTS CRSA - Conception et Réalisation de Systèmes Automatiques'},
            {id: 984, nom: 'BTS MS MSP - Maintenance des Systèmes, option Production'}
          ]
        }
      ]
    },
    {
      nom: `- SITE DE VAUX LE PENIL -`,
      formations: [
        {
          id: 'GROUP-2',
          nomGroup: '',
          groupFormations: [
            {id: 987, nom: 'BAC PRO MELEC - Métiers de l’Electricité et de ses Environnements Connectés'},
            {id: 983, nom: 'BTS ELECTROTECHNIQUE'},
            {id: 982, nom: 'BTS CPI - Conception de Produits Industriels'},
            {id: 988, nom: 'Licence Pro CAIE - Chargé d’Affaires en Installations Électriques'}
          ]
        }
      ]
    }
  ]
};
